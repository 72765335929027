// import { NProgress } from 'nprogress'
import 'nprogress/nprogress.css'
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    redirect: 'Home/IndexTab',
    children: [
      {
        path: 'IndexTab',
        name: 'IndexTab',
        component: () => import('../views/IndexTab.vue'),
        meta: {
          isShow: true,
          title: '首页',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'liquorEnterpriseCenter',
        name: 'liquorEnterpriseCenter',
        component: () =>
          import('../views/liquorEnterprises/LiquorEnterpriseCenter.vue'),
        meta: {
          isShow: true,
          title: '酒企中心',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'product',
        name: 'product',
        component: () =>
          import('../views/product/ProductCenter.vue'),
        meta: {
          isShow: true,
          title: '酒品中心',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'brand',
        name: 'brand',
        component: () =>
          import('../views/brand/BrandStory.vue'),
        meta: {
          isShow: true,
          title: '品牌故事',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'news',
        name: 'news',
        component: () =>
          import('../views/news/NewsInformation.vue'),
        meta: {
          isShow: true,
          title: '新闻资讯',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'purchase',
        name: 'purchase',
        component: () =>
          import('../views/purchase/PurchaseGuide.vue'),
        meta: {
          isShow: true,
          title: '购买指南',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'settled',
        name: 'settled',
        component: () =>
          import('../views/settled/EntryGuide.vue'),
        meta: {
          isShow: true,
          title: '入驻指南',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () =>
          import('../views/contact/ContactUs.vue'),
        meta: {
          isShow: true,
          title: '关于我们',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: 'personalCenter',
        name: 'personalCenter',
        component: () => import(/* webpackChunkName: "about" */ '../views/PersonalCenter.vue'),
        meta: {
          isShow: false,
          title: '个人中心'
        }
      },
      {
        path: 'nwsDetails/:newsId',
        name: 'nwsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/components/NewsDetails.vue'),
        meta: {
          isShow: false,
          title: '新闻详情'
        }
      },
      {
        path: 'lightning',
        name: 'lightning',
        component: () => import(/* webpackChunkName: "about" */ '../views/homePage/LightningDealPage.vue'),
        meta: {
          isShow: false,
          title: '限时秒杀'
        }
      },
      {
        path: 'discountPage',
        name: 'discountPage',
        component: () => import(/* webpackChunkName: "about" */ '../views/homePage/DiscountPage.vue'),
        meta: {
          isShow: false,
          title: '折扣商品'
        }
      },
      {
        path: 'productDetails',
        name: 'productDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/components/ProductDetails.vue'),
        meta: {
          isShow: false,
          title: '商品详情'
        }
      },
      {
        path: 'productCategory',
        name: 'productCategory',
        component: () => import(/* w */ '../views/homePage/ProductCategoryPage.vue'),
        meta: {
          isShow: false,
          title: '分类赛选'
        }
      },
      {
        path: 'search',
        name: 'search',
        component: () => import(/* w */ '../views/homePage/SearchPage.vue'),
        meta: {
          isShow: false,
          title: '搜索页面'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isShow: false
    }
  },
  {
    path: '/',
    name: 'index',
    redirect: '/Home/IndexTab'
  }
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
//
// export default router
const router = createRouter({
  // createWebHashHistory    history: createWebHistory(),hash 和 history
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   // NProgress.start()
//   const token = localStorage.getItem('token')
//   if (!token && to.path !== '/Home' && to.path !== '/register') { // login
//     next({ path: '/Home' }) // login
//   } else if (to.path === '/') {
//     next({ path: '/login' }) // Home
//   } else if (to.matched.length === 0) { // 如果未匹配到路由
//     from.path ? next({ path: from.path }) : next('/') // 如果上级也未匹配到路由则跳转主页面，如果上级能匹配到则转上级路由
//   } else {
//     next()
//   }
//   // NProgress.done()
// })

export default router
