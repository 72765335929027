<template>
  <div class="login">
    <h4>酒博城登录</h4>
    <el-card class="login-info">
      <el-header class="login-top-title"> 用户登录</el-header>
      <el-container>
        <el-form
          ref="formRef"
          :model="ruleForm"
          :rules="rules"
          style="width: 100%"
        >
          <el-form-item prop="username">
            <img
              class="form-input-img"
              src="@/assets/images/login-user.png"
              alt=""
              srcset=""
            />
<!--            prefix-icon="iconfont icon-denglu"-->
            <el-input
              type="text"
              v-model="ruleForm.username"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <img
              class="form-input-img"
              src="@/assets/images/pwd-icon.png"
              alt=""
              srcset=""
            />
<!--            @keyup.enter="login   prefix-icon="iconfont icon-mima""-->
            <el-input
              type="password"
              v-model="ruleForm.password"
              placeholder="密   码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox
              style="margin-left: 20px"
              label="记住密码"
              v-model="remember"
            ></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="login"
            >登录
            </el-button
            >
          </el-form-item>
        </el-form>
      </el-container>
    </el-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
// import { ILogin } from '@/typings/login'
import { Store, useStore } from 'vuex'
import { IState } from '@/store/typesState'

export default defineComponent({
  name: 'Login',
  setup () {
    const formRef = ref(null)
    const store: Store<IState> = useStore()
    // const ruleForm: ILogin = reactive(
    //   store.state.rememb.username
    //     ? store.state.rememb : {
    //       username: '',
    //       password: '',
    //       appId: 2
    //     }
    // )
    const rules = {
      username: [
        {
          type: 'string',
          required: true,
          message: '请输入用户名!',
          trigger: 'change'
        }
      ],
      password: [
        {
          type: 'string',
          required: true,
          message: '请输入密码!',
          trigger: 'change'
        }
      ]
    }
    // const ruleForm: ILogin = reactive({
    //   username: '',
    //   password: '',
    //   appId: 2
    // })
    const login = () => {
      const from: any = formRef.value
      console.log(from)
    }
    return {
      formRef,
      // ruleForm,
      rules,
      login
    }
  }
})
</script>

<style lang="scss" scoped>
.el-header,
.icon-denglu1 {
  font-size: 20px;
  color: #666666;
}

.el-row {
  margin-bottom: 5px;
  font-size: 14px;
  color: #777777;
}
:deep(.el-card) {
  border: 5px solid rgba(52, 99, 255, 0.55);
  border-radius: 4px;
}
.login {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
}
.login-top-title {
  height: auto !important;
  text-align: center;
  padding-top: 42px;
  padding-bottom: 40px;
  font-size: 20px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-weight: 500;
  color: #0777d8;
  letter-spacing: 3px;
}
.login::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  content: "";
  background-image: url("../assets/images/bas-g.jpg");
  background-size: 100% 100%;
}
.form-input-img {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 999;
}
:deep(.el-container) {
  width: 304px;
  margin: 0 auto;
}
:deep(.el-input__inner) {
  //background: rgba(229, 244, 255, 1);
  //border: 1px solid rgba(223, 223, 223, 1);
  border-radius:4px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  padding-left: 43px !important;
  color: #7e7e7e;
}
:deep(.el-card__body) {
  padding: 0;
  height: 100%;
}
.login-info {
  position: absolute;
  width: 450px;
  height: 370px;
  right: 120px;
  top: 60%;
  margin-top: -255px;
}
h4 {
  //padding-left: 373px;
  padding-top: 38px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 34px;
  //color: #d9d9d9;
  color: #42b983;
}
.el-button {
  border-radius: 4px;
  background: linear-gradient(
      51deg,
      rgba(21, 8, 255, 1) 0%,
      rgba(0, 169, 255, 1) 74%
  );
}
</style>
