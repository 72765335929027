import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElMessage from 'element-plus'
import * as echarts from 'echarts'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from './store'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/style/index.scss'
import 'element-plus/dist/index.css'

// createApp(App).use(router).mount('#app')
const app = createApp(App)
// app.config.globalProperties.$message = ElMessage
app.use(store)
app.use(router)
app.use(ElMessage)
app.mount('#app')
app.config.globalProperties.$echarts = echarts
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

export default app.config
